.v-middle { 
    vertical-align: middle !important; 
}

.v-bottom { 
    vertical-align: bottom !important; 
}

.width-20 {
    width: 20px !important;
}

.width-30 {
    width: 30px !important;
}

.width-40 {
    width: 40px !important;
}

.width-50 {
    width: 50px !important;
}

.width-60 {
    width: 60px !important;
}

.width-70 {
    width: 70px !important;
}

.width-80 {
    width: 80px !important;
}

.width-90 {
    width: 90px !important;
}

.width-100 {
    width: 100px !important;
}

.width-110 {
    width: 110px !important;
}

.width-120 {
    width: 120px !important;
}

.width-130 {
    width: 130px !important;
}

.width-140 {
    width: 140px !important;
}

.width-150 {
    width: 150px !important;
}

.width-160 {
    width: 160px !important;
}

.width-170 {
    width: 170px !important;
}

.width-180 {
    width: 180px !important;
}

.width-190 {
    width: 190px !important;
}

.width-200 {
    width: 200px !important;
}

.width-210 {
    width: 210px !important;
}

.width-220 {
    width: 220px !important;
}

.width-230 {
    width: 230px !important;
}

.width-240 {
    width: 240px !important;
}

.width-250 {
    width: 250px !important;
}

.width-260 {
    width: 260px !important;
}

.width-270 {
    width: 270px !important;
}

.width-280 {
    width: 280px !important;
}

.width-290 {
    width: 290px !important;
}

.width-300 {
    width: 300px !important;
}

.width-310 {
    width: 310px !important;
}

.width-320 {
    width: 320px !important;
}

.width-330 {
    width: 330px !important;
}

.width-340 {
    width: 340px !important;
}

.width-350 {
    width: 350px !important;
}

.width-360 {
    width: 360px !important;
}

.width-370 {
    width: 370px !important;
}

.width-380 {
    width: 380px !important;
}

.width-390 {
    width: 390px !important;
}

.width-400 {
    width: 400px !important;
}

.width-410 {
    width: 410px !important;
}

.width-420 {
    width: 420px !important;
}

.width-430 {
    width: 430px !important;
}

.width-440 {
    width: 440px !important;
}

.width-450 {
    width: 450px !important;
}

.width-460 {
    width: 460px !important;
}

.width-470 {
    width: 470px !important;
}

.width-480 {
    width: 480px !important;
}

.width-490 {
    width: 490px !important;
}

.width-500 {
    width: 500px !important;
}

.width-510 {
    width: 510px !important;
}

.width-520 {
    width: 520px !important;
}

.width-530 {
    width: 530px !important;
}

.width-540 {
    width: 540px !important;
}

.width-550 {
    width: 550px !important;
}

.width-560 {
    width: 560px !important;
}

.width-570 {
    width: 570px !important;
}

.width-580 {
    width: 580px !important;
}

.width-590 {
    width: 590px !important;
}

.width-600 {
    width: 600px !important;
}

.margin-none {
    margin: 0 !important;
}

.margin-v-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}
.margin-v-15-10 {
  margin-top: 15px !important;
  margin-bottom: 10px !important;
}
.margin-v-15-9 {
  margin-top: 15px !important;
  margin-bottom: 9px !important;
}
.margin-v-15-8 {
  margin-top: 15px !important;
  margin-bottom: 8px !important;
}
.margin-v-15-7 {
  margin-top: 15px !important;
  margin-bottom: 7px !important;
}
.margin-v-15-6 {
  margin-top: 15px !important;
  margin-bottom: 6px !important;
}
.margin-v-15-5 {
  margin-top: 15px !important;
  margin-bottom: 5px !important;
}
.margin-v-15-4 {
  margin-top: 15px !important;
  margin-bottom: 4px !important;
}
.margin-v-15-3 {
  margin-top: 15px !important;
  margin-bottom: 3px !important;
}
.margin-v-15-2 {
  margin-top: 15px !important;
  margin-bottom: 2px !important;
}
.margin-v-15-1 {
  margin-top: 15px !important;
  margin-bottom: 1px !important;
}
.margin-v-15-0 {
  margin-top: 15px !important;
  margin-bottom: 0px !important;
}
.margin-v-14 {
  margin-top: 14px !important;
  margin-bottom: 14px !important;
}
.margin-v-14-10 {
  margin-top: 14px !important;
  margin-bottom: 10px !important;
}
.margin-v-14-9 {
  margin-top: 14px !important;
  margin-bottom: 9px !important;
}
.margin-v-14-8 {
  margin-top: 14px !important;
  margin-bottom: 8px !important;
}
.margin-v-14-7 {
  margin-top: 14px !important;
  margin-bottom: 7px !important;
}
.margin-v-14-6 {
  margin-top: 14px !important;
  margin-bottom: 6px !important;
}
.margin-v-14-5 {
  margin-top: 14px !important;
  margin-bottom: 5px !important;
}
.margin-v-14-4 {
  margin-top: 14px !important;
  margin-bottom: 4px !important;
}
.margin-v-14-3 {
  margin-top: 14px !important;
  margin-bottom: 3px !important;
}
.margin-v-14-2 {
  margin-top: 14px !important;
  margin-bottom: 2px !important;
}
.margin-v-14-1 {
  margin-top: 14px !important;
  margin-bottom: 1px !important;
}
.margin-v-14-0 {
  margin-top: 14px !important;
  margin-bottom: 0px !important;
}
.margin-v-13 {
  margin-top: 13px !important;
  margin-bottom: 13px !important;
}
.margin-v-13-10 {
  margin-top: 13px !important;
  margin-bottom: 10px !important;
}
.margin-v-13-9 {
  margin-top: 13px !important;
  margin-bottom: 9px !important;
}
.margin-v-13-8 {
  margin-top: 13px !important;
  margin-bottom: 8px !important;
}
.margin-v-13-7 {
  margin-top: 13px !important;
  margin-bottom: 7px !important;
}
.margin-v-13-6 {
  margin-top: 13px !important;
  margin-bottom: 6px !important;
}
.margin-v-13-5 {
  margin-top: 13px !important;
  margin-bottom: 5px !important;
}
.margin-v-13-4 {
  margin-top: 13px !important;
  margin-bottom: 4px !important;
}
.margin-v-13-3 {
  margin-top: 13px !important;
  margin-bottom: 3px !important;
}
.margin-v-13-2 {
  margin-top: 13px !important;
  margin-bottom: 2px !important;
}
.margin-v-13-1 {
  margin-top: 13px !important;
  margin-bottom: 1px !important;
}
.margin-v-13-0 {
  margin-top: 13px !important;
  margin-bottom: 0px !important;
}
.margin-v-12 {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}
.margin-v-12-10 {
  margin-top: 12px !important;
  margin-bottom: 10px !important;
}
.margin-v-12-9 {
  margin-top: 12px !important;
  margin-bottom: 9px !important;
}
.margin-v-12-8 {
  margin-top: 12px !important;
  margin-bottom: 8px !important;
}
.margin-v-12-7 {
  margin-top: 12px !important;
  margin-bottom: 7px !important;
}
.margin-v-12-6 {
  margin-top: 12px !important;
  margin-bottom: 6px !important;
}
.margin-v-12-5 {
  margin-top: 12px !important;
  margin-bottom: 5px !important;
}
.margin-v-12-4 {
  margin-top: 12px !important;
  margin-bottom: 4px !important;
}
.margin-v-12-3 {
  margin-top: 12px !important;
  margin-bottom: 3px !important;
}
.margin-v-12-2 {
  margin-top: 12px !important;
  margin-bottom: 2px !important;
}
.margin-v-12-1 {
  margin-top: 12px !important;
  margin-bottom: 1px !important;
}
.margin-v-12-0 {
  margin-top: 12px !important;
  margin-bottom: 0px !important;
}
.margin-v-11 {
  margin-top: 11px !important;
  margin-bottom: 11px !important;
}
.margin-v-11-10 {
  margin-top: 11px !important;
  margin-bottom: 10px !important;
}
.margin-v-11-9 {
  margin-top: 11px !important;
  margin-bottom: 9px !important;
}
.margin-v-11-8 {
  margin-top: 11px !important;
  margin-bottom: 8px !important;
}
.margin-v-11-7 {
  margin-top: 11px !important;
  margin-bottom: 7px !important;
}
.margin-v-11-6 {
  margin-top: 11px !important;
  margin-bottom: 6px !important;
}
.margin-v-11-5 {
  margin-top: 11px !important;
  margin-bottom: 5px !important;
}
.margin-v-11-4 {
  margin-top: 11px !important;
  margin-bottom: 4px !important;
}
.margin-v-11-3 {
  margin-top: 11px !important;
  margin-bottom: 3px !important;
}
.margin-v-11-2 {
  margin-top: 11px !important;
  margin-bottom: 2px !important;
}
.margin-v-11-1 {
  margin-top: 11px !important;
  margin-bottom: 1px !important;
}
.margin-v-11-0 {
  margin-top: 11px !important;
  margin-bottom: 0px !important;
}
.margin-v-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.margin-v-10-9 {
  margin-top: 10px !important;
  margin-bottom: 9px !important;
}
.margin-v-10-8 {
  margin-top: 10px !important;
  margin-bottom: 8px !important;
}
.margin-v-10-7 {
  margin-top: 10px !important;
  margin-bottom: 7px !important;
}
.margin-v-10-6 {
  margin-top: 10px !important;
  margin-bottom: 6px !important;
}
.margin-v-10-5 {
  margin-top: 10px !important;
  margin-bottom: 5px !important;
}
.margin-v-10-4 {
  margin-top: 10px !important;
  margin-bottom: 4px !important;
}
.margin-v-10-3 {
  margin-top: 10px !important;
  margin-bottom: 3px !important;
}
.margin-v-10-2 {
  margin-top: 10px !important;
  margin-bottom: 2px !important;
}
.margin-v-10-1 {
  margin-top: 10px !important;
  margin-bottom: 1px !important;
}
.margin-v-10-0 {
  margin-top: 10px !important;
  margin-bottom: 0px !important;
}
.margin-v-9 {
  margin-top: 9px !important;
  margin-bottom: 9px !important;
}
.margin-v-9-10 {
  margin-top: 9px !important;
  margin-bottom: 10px !important;
}
.margin-v-9-8 {
  margin-top: 9px !important;
  margin-bottom: 8px !important;
}
.margin-v-9-7 {
  margin-top: 9px !important;
  margin-bottom: 7px !important;
}
.margin-v-9-6 {
  margin-top: 9px !important;
  margin-bottom: 6px !important;
}
.margin-v-9-5 {
  margin-top: 9px !important;
  margin-bottom: 5px !important;
}
.margin-v-9-4 {
  margin-top: 9px !important;
  margin-bottom: 4px !important;
}
.margin-v-9-3 {
  margin-top: 9px !important;
  margin-bottom: 3px !important;
}
.margin-v-9-2 {
  margin-top: 9px !important;
  margin-bottom: 2px !important;
}
.margin-v-9-1 {
  margin-top: 9px !important;
  margin-bottom: 1px !important;
}
.margin-v-9-0 {
  margin-top: 9px !important;
  margin-bottom: 0px !important;
}
.margin-v-8 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}
.margin-v-8-10 {
  margin-top: 8px !important;
  margin-bottom: 10px !important;
}
.margin-v-8-9 {
  margin-top: 8px !important;
  margin-bottom: 9px !important;
}
.margin-v-8-7 {
  margin-top: 8px !important;
  margin-bottom: 7px !important;
}
.margin-v-8-6 {
  margin-top: 8px !important;
  margin-bottom: 6px !important;
}
.margin-v-8-5 {
  margin-top: 8px !important;
  margin-bottom: 5px !important;
}
.margin-v-8-4 {
  margin-top: 8px !important;
  margin-bottom: 4px !important;
}
.margin-v-8-3 {
  margin-top: 8px !important;
  margin-bottom: 3px !important;
}
.margin-v-8-2 {
  margin-top: 8px !important;
  margin-bottom: 2px !important;
}
.margin-v-8-1 {
  margin-top: 8px !important;
  margin-bottom: 1px !important;
}
.margin-v-8-0 {
  margin-top: 8px !important;
  margin-bottom: 0px !important;
}
.margin-v-7 {
  margin-top: 7px !important;
  margin-bottom: 7px !important;
}
.margin-v-7-10 {
  margin-top: 7px !important;
  margin-bottom: 10px !important;
}
.margin-v-7-9 {
  margin-top: 7px !important;
  margin-bottom: 9px !important;
}
.margin-v-7-8 {
  margin-top: 7px !important;
  margin-bottom: 8px !important;
}
.margin-v-7-6 {
  margin-top: 7px !important;
  margin-bottom: 6px !important;
}
.margin-v-7-5 {
  margin-top: 7px !important;
  margin-bottom: 5px !important;
}
.margin-v-7-4 {
  margin-top: 7px !important;
  margin-bottom: 4px !important;
}
.margin-v-7-3 {
  margin-top: 7px !important;
  margin-bottom: 3px !important;
}
.margin-v-7-2 {
  margin-top: 7px !important;
  margin-bottom: 2px !important;
}
.margin-v-7-1 {
  margin-top: 7px !important;
  margin-bottom: 1px !important;
}
.margin-v-7-0 {
  margin-top: 7px !important;
  margin-bottom: 0px !important;
}
.margin-v-6 {
  margin-top: 6px !important;
  margin-bottom: 6px !important;
}
.margin-v-6-10 {
  margin-top: 6px !important;
  margin-bottom: 10px !important;
}
.margin-v-6-9 {
  margin-top: 6px !important;
  margin-bottom: 9px !important;
}
.margin-v-6-8 {
  margin-top: 6px !important;
  margin-bottom: 8px !important;
}
.margin-v-6-7 {
  margin-top: 6px !important;
  margin-bottom: 7px !important;
}
.margin-v-6-5 {
  margin-top: 6px !important;
  margin-bottom: 5px !important;
}
.margin-v-6-4 {
  margin-top: 6px !important;
  margin-bottom: 4px !important;
}
.margin-v-6-3 {
  margin-top: 6px !important;
  margin-bottom: 3px !important;
}
.margin-v-6-2 {
  margin-top: 6px !important;
  margin-bottom: 2px !important;
}
.margin-v-6-1 {
  margin-top: 6px !important;
  margin-bottom: 1px !important;
}
.margin-v-6-0 {
  margin-top: 6px !important;
  margin-bottom: 0px !important;
}
.margin-v-5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}
.margin-v-5-10 {
  margin-top: 5px !important;
  margin-bottom: 10px !important;
}
.margin-v-5-9 {
  margin-top: 5px !important;
  margin-bottom: 9px !important;
}
.margin-v-5-8 {
  margin-top: 5px !important;
  margin-bottom: 8px !important;
}
.margin-v-5-7 {
  margin-top: 5px !important;
  margin-bottom: 7px !important;
}
.margin-v-5-6 {
  margin-top: 5px !important;
  margin-bottom: 6px !important;
}
.margin-v-5-4 {
  margin-top: 5px !important;
  margin-bottom: 4px !important;
}
.margin-v-5-3 {
  margin-top: 5px !important;
  margin-bottom: 3px !important;
}
.margin-v-5-2 {
  margin-top: 5px !important;
  margin-bottom: 2px !important;
}
.margin-v-5-1 {
  margin-top: 5px !important;
  margin-bottom: 1px !important;
}
.margin-v-5-0 {
  margin-top: 5px !important;
  margin-bottom: 0px !important;
}
.margin-v-4 {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}
.margin-v-4-10 {
  margin-top: 4px !important;
  margin-bottom: 10px !important;
}
.margin-v-4-9 {
  margin-top: 4px !important;
  margin-bottom: 9px !important;
}
.margin-v-4-8 {
  margin-top: 4px !important;
  margin-bottom: 8px !important;
}
.margin-v-4-7 {
  margin-top: 4px !important;
  margin-bottom: 7px !important;
}
.margin-v-4-6 {
  margin-top: 4px !important;
  margin-bottom: 6px !important;
}
.margin-v-4-5 {
  margin-top: 4px !important;
  margin-bottom: 5px !important;
}
.margin-v-4-3 {
  margin-top: 4px !important;
  margin-bottom: 3px !important;
}
.margin-v-4-2 {
  margin-top: 4px !important;
  margin-bottom: 2px !important;
}
.margin-v-4-1 {
  margin-top: 4px !important;
  margin-bottom: 1px !important;
}
.margin-v-4-0 {
  margin-top: 4px !important;
  margin-bottom: 0px !important;
}
.margin-v-3 {
  margin-top: 3px !important;
  margin-bottom: 3px !important;
}
.margin-v-3-10 {
  margin-top: 3px !important;
  margin-bottom: 10px !important;
}
.margin-v-3-9 {
  margin-top: 3px !important;
  margin-bottom: 9px !important;
}
.margin-v-3-8 {
  margin-top: 3px !important;
  margin-bottom: 8px !important;
}
.margin-v-3-7 {
  margin-top: 3px !important;
  margin-bottom: 7px !important;
}
.margin-v-3-6 {
  margin-top: 3px !important;
  margin-bottom: 6px !important;
}
.margin-v-3-5 {
  margin-top: 3px !important;
  margin-bottom: 5px !important;
}
.margin-v-3-4 {
  margin-top: 3px !important;
  margin-bottom: 4px !important;
}
.margin-v-3-2 {
  margin-top: 3px !important;
  margin-bottom: 2px !important;
}
.margin-v-3-1 {
  margin-top: 3px !important;
  margin-bottom: 1px !important;
}
.margin-v-3-0 {
  margin-top: 3px !important;
  margin-bottom: 0px !important;
}
.margin-v-2 {
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}
.margin-v-2-10 {
  margin-top: 2px !important;
  margin-bottom: 10px !important;
}
.margin-v-2-9 {
  margin-top: 2px !important;
  margin-bottom: 9px !important;
}
.margin-v-2-8 {
  margin-top: 2px !important;
  margin-bottom: 8px !important;
}
.margin-v-2-7 {
  margin-top: 2px !important;
  margin-bottom: 7px !important;
}
.margin-v-2-6 {
  margin-top: 2px !important;
  margin-bottom: 6px !important;
}
.margin-v-2-5 {
  margin-top: 2px !important;
  margin-bottom: 5px !important;
}
.margin-v-2-4 {
  margin-top: 2px !important;
  margin-bottom: 4px !important;
}
.margin-v-2-3 {
  margin-top: 2px !important;
  margin-bottom: 3px !important;
}
.margin-v-2-1 {
  margin-top: 2px !important;
  margin-bottom: 1px !important;
}
.margin-v-2-0 {
  margin-top: 2px !important;
  margin-bottom: 0px !important;
}
.margin-v-1 {
  margin-top: 1px !important;
  margin-bottom: 1px !important;
}
.margin-v-1-10 {
  margin-top: 1px !important;
  margin-bottom: 10px !important;
}
.margin-v-1-9 {
  margin-top: 1px !important;
  margin-bottom: 9px !important;
}
.margin-v-1-8 {
  margin-top: 1px !important;
  margin-bottom: 8px !important;
}
.margin-v-1-7 {
  margin-top: 1px !important;
  margin-bottom: 7px !important;
}
.margin-v-1-6 {
  margin-top: 1px !important;
  margin-bottom: 6px !important;
}
.margin-v-1-5 {
  margin-top: 1px !important;
  margin-bottom: 5px !important;
}
.margin-v-1-4 {
  margin-top: 1px !important;
  margin-bottom: 4px !important;
}
.margin-v-1-3 {
  margin-top: 1px !important;
  margin-bottom: 3px !important;
}
.margin-v-1-2 {
  margin-top: 1px !important;
  margin-bottom: 2px !important;
}
.margin-v-1-0 {
  margin-top: 1px !important;
  margin-bottom: 0px !important;
}
.margin-v-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.margin-v-0-10 {
  margin-top: 0px !important;
  margin-bottom: 10px !important;
}
.margin-v-0-9 {
  margin-top: 0px !important;
  margin-bottom: 9px !important;
}
.margin-v-0-8 {
  margin-top: 0px !important;
  margin-bottom: 8px !important;
}
.margin-v-0-7 {
  margin-top: 0px !important;
  margin-bottom: 7px !important;
}
.margin-v-0-6 {
  margin-top: 0px !important;
  margin-bottom: 6px !important;
}
.margin-v-0-5 {
  margin-top: 0px !important;
  margin-bottom: 5px !important;
}
.margin-v-0-4 {
  margin-top: 0px !important;
  margin-bottom: 4px !important;
}
.margin-v-0-3 {
  margin-top: 0px !important;
  margin-bottom: 3px !important;
}
.margin-v-0-2 {
  margin-top: 0px !important;
  margin-bottom: 2px !important;
}
.margin-v-0-1 {
  margin-top: 0px !important;
  margin-bottom: 1px !important;
}
.margin-v-0-15 {
  margin-top: 0px !important;
  margin-bottom: 15px !important;
}
.margin-v-0-14 {
  margin-top: 0px !important;
  margin-bottom: 14px !important;
}
.margin-v-0-13 {
  margin-top: 0px !important;
  margin-bottom: 13px !important;
}
.margin-v-0-12 {
  margin-top: 0px !important;
  margin-bottom: 12px !important;
}
.margin-v-0-11 {
  margin-top: 0px !important;
  margin-bottom: 11px !important;
}
.margin-v-0-10 {
  margin-top: 0px !important;
  margin-bottom: 10px !important;
}
.margin-v-0-9 {
  margin-top: 0px !important;
  margin-bottom: 9px !important;
}
.margin-v-0-8 {
  margin-top: 0px !important;
  margin-bottom: 8px !important;
}
.margin-v-0-7 {
  margin-top: 0px !important;
  margin-bottom: 7px !important;
}
.margin-v-0-6 {
  margin-top: 0px !important;
  margin-bottom: 6px !important;
}
.margin-v-0-5 {
  margin-top: 0px !important;
  margin-bottom: 5px !important;
}
.margin-v-0-4 {
  margin-top: 0px !important;
  margin-bottom: 4px !important;
}
.margin-v-0-3 {
  margin-top: 0px !important;
  margin-bottom: 3px !important;
}
.margin-v-0-2 {
  margin-top: 0px !important;
  margin-bottom: 2px !important;
}
.margin-v-0-1 {
  margin-top: 0px !important;
  margin-bottom: 1px !important;
}
.margin-v-1-15 {
  margin-top: 1px !important;
  margin-bottom: 15px !important;
}
.margin-v-1-14 {
  margin-top: 1px !important;
  margin-bottom: 14px !important;
}
.margin-v-1-13 {
  margin-top: 1px !important;
  margin-bottom: 13px !important;
}
.margin-v-1-12 {
  margin-top: 1px !important;
  margin-bottom: 12px !important;
}
.margin-v-1-11 {
  margin-top: 1px !important;
  margin-bottom: 11px !important;
}
.margin-v-1-10 {
  margin-top: 1px !important;
  margin-bottom: 10px !important;
}
.margin-v-1-9 {
  margin-top: 1px !important;
  margin-bottom: 9px !important;
}
.margin-v-1-8 {
  margin-top: 1px !important;
  margin-bottom: 8px !important;
}
.margin-v-1-7 {
  margin-top: 1px !important;
  margin-bottom: 7px !important;
}
.margin-v-1-6 {
  margin-top: 1px !important;
  margin-bottom: 6px !important;
}
.margin-v-1-5 {
  margin-top: 1px !important;
  margin-bottom: 5px !important;
}
.margin-v-1-4 {
  margin-top: 1px !important;
  margin-bottom: 4px !important;
}
.margin-v-1-3 {
  margin-top: 1px !important;
  margin-bottom: 3px !important;
}
.margin-v-1-2 {
  margin-top: 1px !important;
  margin-bottom: 2px !important;
}
.margin-v-1-0 {
  margin-top: 1px !important;
  margin-bottom: 0px !important;
}
.margin-v-2-15 {
  margin-top: 2px !important;
  margin-bottom: 15px !important;
}
.margin-v-2-14 {
  margin-top: 2px !important;
  margin-bottom: 14px !important;
}
.margin-v-2-13 {
  margin-top: 2px !important;
  margin-bottom: 13px !important;
}
.margin-v-2-12 {
  margin-top: 2px !important;
  margin-bottom: 12px !important;
}
.margin-v-2-11 {
  margin-top: 2px !important;
  margin-bottom: 11px !important;
}
.margin-v-2-10 {
  margin-top: 2px !important;
  margin-bottom: 10px !important;
}
.margin-v-2-9 {
  margin-top: 2px !important;
  margin-bottom: 9px !important;
}
.margin-v-2-8 {
  margin-top: 2px !important;
  margin-bottom: 8px !important;
}
.margin-v-2-7 {
  margin-top: 2px !important;
  margin-bottom: 7px !important;
}
.margin-v-2-6 {
  margin-top: 2px !important;
  margin-bottom: 6px !important;
}
.margin-v-2-5 {
  margin-top: 2px !important;
  margin-bottom: 5px !important;
}
.margin-v-2-4 {
  margin-top: 2px !important;
  margin-bottom: 4px !important;
}
.margin-v-2-3 {
  margin-top: 2px !important;
  margin-bottom: 3px !important;
}
.margin-v-2-1 {
  margin-top: 2px !important;
  margin-bottom: 1px !important;
}
.margin-v-2-0 {
  margin-top: 2px !important;
  margin-bottom: 0px !important;
}
.margin-v-3-15 {
  margin-top: 3px !important;
  margin-bottom: 15px !important;
}
.margin-v-3-14 {
  margin-top: 3px !important;
  margin-bottom: 14px !important;
}
.margin-v-3-13 {
  margin-top: 3px !important;
  margin-bottom: 13px !important;
}
.margin-v-3-12 {
  margin-top: 3px !important;
  margin-bottom: 12px !important;
}
.margin-v-3-11 {
  margin-top: 3px !important;
  margin-bottom: 11px !important;
}
.margin-v-3-10 {
  margin-top: 3px !important;
  margin-bottom: 10px !important;
}
.margin-v-3-9 {
  margin-top: 3px !important;
  margin-bottom: 9px !important;
}
.margin-v-3-8 {
  margin-top: 3px !important;
  margin-bottom: 8px !important;
}
.margin-v-3-7 {
  margin-top: 3px !important;
  margin-bottom: 7px !important;
}
.margin-v-3-6 {
  margin-top: 3px !important;
  margin-bottom: 6px !important;
}
.margin-v-3-5 {
  margin-top: 3px !important;
  margin-bottom: 5px !important;
}
.margin-v-3-4 {
  margin-top: 3px !important;
  margin-bottom: 4px !important;
}
.margin-v-3-2 {
  margin-top: 3px !important;
  margin-bottom: 2px !important;
}
.margin-v-3-1 {
  margin-top: 3px !important;
  margin-bottom: 1px !important;
}
.margin-v-3-0 {
  margin-top: 3px !important;
  margin-bottom: 0px !important;
}
.margin-v-4-15 {
  margin-top: 4px !important;
  margin-bottom: 15px !important;
}
.margin-v-4-14 {
  margin-top: 4px !important;
  margin-bottom: 14px !important;
}
.margin-v-4-13 {
  margin-top: 4px !important;
  margin-bottom: 13px !important;
}
.margin-v-4-12 {
  margin-top: 4px !important;
  margin-bottom: 12px !important;
}
.margin-v-4-11 {
  margin-top: 4px !important;
  margin-bottom: 11px !important;
}
.margin-v-4-10 {
  margin-top: 4px !important;
  margin-bottom: 10px !important;
}
.margin-v-4-9 {
  margin-top: 4px !important;
  margin-bottom: 9px !important;
}
.margin-v-4-8 {
  margin-top: 4px !important;
  margin-bottom: 8px !important;
}
.margin-v-4-7 {
  margin-top: 4px !important;
  margin-bottom: 7px !important;
}
.margin-v-4-6 {
  margin-top: 4px !important;
  margin-bottom: 6px !important;
}
.margin-v-4-5 {
  margin-top: 4px !important;
  margin-bottom: 5px !important;
}
.margin-v-4-3 {
  margin-top: 4px !important;
  margin-bottom: 3px !important;
}
.margin-v-4-2 {
  margin-top: 4px !important;
  margin-bottom: 2px !important;
}
.margin-v-4-1 {
  margin-top: 4px !important;
  margin-bottom: 1px !important;
}
.margin-v-4-0 {
  margin-top: 4px !important;
  margin-bottom: 0px !important;
}
.margin-v-5-15 {
  margin-top: 5px !important;
  margin-bottom: 15px !important;
}
.margin-v-5-14 {
  margin-top: 5px !important;
  margin-bottom: 14px !important;
}
.margin-v-5-13 {
  margin-top: 5px !important;
  margin-bottom: 13px !important;
}
.margin-v-5-12 {
  margin-top: 5px !important;
  margin-bottom: 12px !important;
}
.margin-v-5-11 {
  margin-top: 5px !important;
  margin-bottom: 11px !important;
}
.margin-v-5-10 {
  margin-top: 5px !important;
  margin-bottom: 10px !important;
}
.margin-v-5-9 {
  margin-top: 5px !important;
  margin-bottom: 9px !important;
}
.margin-v-5-8 {
  margin-top: 5px !important;
  margin-bottom: 8px !important;
}
.margin-v-5-7 {
  margin-top: 5px !important;
  margin-bottom: 7px !important;
}
.margin-v-5-6 {
  margin-top: 5px !important;
  margin-bottom: 6px !important;
}
.margin-v-5-4 {
  margin-top: 5px !important;
  margin-bottom: 4px !important;
}
.margin-v-5-3 {
  margin-top: 5px !important;
  margin-bottom: 3px !important;
}
.margin-v-5-2 {
  margin-top: 5px !important;
  margin-bottom: 2px !important;
}
.margin-v-5-1 {
  margin-top: 5px !important;
  margin-bottom: 1px !important;
}
.margin-v-5-0 {
  margin-top: 5px !important;
  margin-bottom: 0px !important;
}
.margin-v-6-15 {
  margin-top: 6px !important;
  margin-bottom: 15px !important;
}
.margin-v-6-14 {
  margin-top: 6px !important;
  margin-bottom: 14px !important;
}
.margin-v-6-13 {
  margin-top: 6px !important;
  margin-bottom: 13px !important;
}
.margin-v-6-12 {
  margin-top: 6px !important;
  margin-bottom: 12px !important;
}
.margin-v-6-11 {
  margin-top: 6px !important;
  margin-bottom: 11px !important;
}
.margin-v-6-10 {
  margin-top: 6px !important;
  margin-bottom: 10px !important;
}
.margin-v-6-9 {
  margin-top: 6px !important;
  margin-bottom: 9px !important;
}
.margin-v-6-8 {
  margin-top: 6px !important;
  margin-bottom: 8px !important;
}
.margin-v-6-7 {
  margin-top: 6px !important;
  margin-bottom: 7px !important;
}
.margin-v-6-5 {
  margin-top: 6px !important;
  margin-bottom: 5px !important;
}
.margin-v-6-4 {
  margin-top: 6px !important;
  margin-bottom: 4px !important;
}
.margin-v-6-3 {
  margin-top: 6px !important;
  margin-bottom: 3px !important;
}
.margin-v-6-2 {
  margin-top: 6px !important;
  margin-bottom: 2px !important;
}
.margin-v-6-1 {
  margin-top: 6px !important;
  margin-bottom: 1px !important;
}
.margin-v-6-0 {
  margin-top: 6px !important;
  margin-bottom: 0px !important;
}
.margin-v-7-15 {
  margin-top: 7px !important;
  margin-bottom: 15px !important;
}
.margin-v-7-14 {
  margin-top: 7px !important;
  margin-bottom: 14px !important;
}
.margin-v-7-13 {
  margin-top: 7px !important;
  margin-bottom: 13px !important;
}
.margin-v-7-12 {
  margin-top: 7px !important;
  margin-bottom: 12px !important;
}
.margin-v-7-11 {
  margin-top: 7px !important;
  margin-bottom: 11px !important;
}
.margin-v-7-10 {
  margin-top: 7px !important;
  margin-bottom: 10px !important;
}
.margin-v-7-9 {
  margin-top: 7px !important;
  margin-bottom: 9px !important;
}
.margin-v-7-8 {
  margin-top: 7px !important;
  margin-bottom: 8px !important;
}
.margin-v-7-6 {
  margin-top: 7px !important;
  margin-bottom: 6px !important;
}
.margin-v-7-5 {
  margin-top: 7px !important;
  margin-bottom: 5px !important;
}
.margin-v-7-4 {
  margin-top: 7px !important;
  margin-bottom: 4px !important;
}
.margin-v-7-3 {
  margin-top: 7px !important;
  margin-bottom: 3px !important;
}
.margin-v-7-2 {
  margin-top: 7px !important;
  margin-bottom: 2px !important;
}
.margin-v-7-1 {
  margin-top: 7px !important;
  margin-bottom: 1px !important;
}
.margin-v-7-0 {
  margin-top: 7px !important;
  margin-bottom: 0px !important;
}
.margin-v-8-15 {
  margin-top: 8px !important;
  margin-bottom: 15px !important;
}
.margin-v-8-14 {
  margin-top: 8px !important;
  margin-bottom: 14px !important;
}
.margin-v-8-13 {
  margin-top: 8px !important;
  margin-bottom: 13px !important;
}
.margin-v-8-12 {
  margin-top: 8px !important;
  margin-bottom: 12px !important;
}
.margin-v-8-11 {
  margin-top: 8px !important;
  margin-bottom: 11px !important;
}
.margin-v-8-10 {
  margin-top: 8px !important;
  margin-bottom: 10px !important;
}
.margin-v-8-9 {
  margin-top: 8px !important;
  margin-bottom: 9px !important;
}
.margin-v-8-7 {
  margin-top: 8px !important;
  margin-bottom: 7px !important;
}
.margin-v-8-6 {
  margin-top: 8px !important;
  margin-bottom: 6px !important;
}
.margin-v-8-5 {
  margin-top: 8px !important;
  margin-bottom: 5px !important;
}
.margin-v-8-4 {
  margin-top: 8px !important;
  margin-bottom: 4px !important;
}
.margin-v-8-3 {
  margin-top: 8px !important;
  margin-bottom: 3px !important;
}
.margin-v-8-2 {
  margin-top: 8px !important;
  margin-bottom: 2px !important;
}
.margin-v-8-1 {
  margin-top: 8px !important;
  margin-bottom: 1px !important;
}
.margin-v-8-0 {
  margin-top: 8px !important;
  margin-bottom: 0px !important;
}
.margin-v-9-15 {
  margin-top: 9px !important;
  margin-bottom: 15px !important;
}
.margin-v-9-14 {
  margin-top: 9px !important;
  margin-bottom: 14px !important;
}
.margin-v-9-13 {
  margin-top: 9px !important;
  margin-bottom: 13px !important;
}
.margin-v-9-12 {
  margin-top: 9px !important;
  margin-bottom: 12px !important;
}
.margin-v-9-11 {
  margin-top: 9px !important;
  margin-bottom: 11px !important;
}
.margin-v-9-10 {
  margin-top: 9px !important;
  margin-bottom: 10px !important;
}
.margin-v-9-8 {
  margin-top: 9px !important;
  margin-bottom: 8px !important;
}
.margin-v-9-7 {
  margin-top: 9px !important;
  margin-bottom: 7px !important;
}
.margin-v-9-6 {
  margin-top: 9px !important;
  margin-bottom: 6px !important;
}
.margin-v-9-5 {
  margin-top: 9px !important;
  margin-bottom: 5px !important;
}
.margin-v-9-4 {
  margin-top: 9px !important;
  margin-bottom: 4px !important;
}
.margin-v-9-3 {
  margin-top: 9px !important;
  margin-bottom: 3px !important;
}
.margin-v-9-2 {
  margin-top: 9px !important;
  margin-bottom: 2px !important;
}
.margin-v-9-1 {
  margin-top: 9px !important;
  margin-bottom: 1px !important;
}
.margin-v-9-0 {
  margin-top: 9px !important;
  margin-bottom: 0px !important;
}
.margin-v-10-15 {
  margin-top: 10px !important;
  margin-bottom: 15px !important;
}
.margin-v-10-14 {
  margin-top: 10px !important;
  margin-bottom: 14px !important;
}
.margin-v-10-13 {
  margin-top: 10px !important;
  margin-bottom: 13px !important;
}
.margin-v-10-12 {
  margin-top: 10px !important;
  margin-bottom: 12px !important;
}
.margin-v-10-11 {
  margin-top: 10px !important;
  margin-bottom: 11px !important;
}
.margin-v-10-9 {
  margin-top: 10px !important;
  margin-bottom: 9px !important;
}
.margin-v-10-8 {
  margin-top: 10px !important;
  margin-bottom: 8px !important;
}
.margin-v-10-7 {
  margin-top: 10px !important;
  margin-bottom: 7px !important;
}
.margin-v-10-6 {
  margin-top: 10px !important;
  margin-bottom: 6px !important;
}
.margin-v-10-5 {
  margin-top: 10px !important;
  margin-bottom: 5px !important;
}
.margin-v-10-4 {
  margin-top: 10px !important;
  margin-bottom: 4px !important;
}
.margin-v-10-3 {
  margin-top: 10px !important;
  margin-bottom: 3px !important;
}
.margin-v-10-2 {
  margin-top: 10px !important;
  margin-bottom: 2px !important;
}
.margin-v-10-1 {
  margin-top: 10px !important;
  margin-bottom: 1px !important;
}
.margin-v-10-0 {
  margin-top: 10px !important;
  margin-bottom: 0px !important;
}
.margin-v-11-15 {
  margin-top: 11px !important;
  margin-bottom: 15px !important;
}
.margin-v-11-14 {
  margin-top: 11px !important;
  margin-bottom: 14px !important;
}
.margin-v-11-13 {
  margin-top: 11px !important;
  margin-bottom: 13px !important;
}
.margin-v-11-12 {
  margin-top: 11px !important;
  margin-bottom: 12px !important;
}
.margin-v-11-10 {
  margin-top: 11px !important;
  margin-bottom: 10px !important;
}
.margin-v-11-9 {
  margin-top: 11px !important;
  margin-bottom: 9px !important;
}
.margin-v-11-8 {
  margin-top: 11px !important;
  margin-bottom: 8px !important;
}
.margin-v-11-7 {
  margin-top: 11px !important;
  margin-bottom: 7px !important;
}
.margin-v-11-6 {
  margin-top: 11px !important;
  margin-bottom: 6px !important;
}
.margin-v-11-5 {
  margin-top: 11px !important;
  margin-bottom: 5px !important;
}
.margin-v-11-4 {
  margin-top: 11px !important;
  margin-bottom: 4px !important;
}
.margin-v-11-3 {
  margin-top: 11px !important;
  margin-bottom: 3px !important;
}
.margin-v-11-2 {
  margin-top: 11px !important;
  margin-bottom: 2px !important;
}
.margin-v-11-1 {
  margin-top: 11px !important;
  margin-bottom: 1px !important;
}
.margin-v-11-0 {
  margin-top: 11px !important;
  margin-bottom: 0px !important;
}
.margin-v-12-15 {
  margin-top: 12px !important;
  margin-bottom: 15px !important;
}
.margin-v-12-14 {
  margin-top: 12px !important;
  margin-bottom: 14px !important;
}
.margin-v-12-13 {
  margin-top: 12px !important;
  margin-bottom: 13px !important;
}
.margin-v-12-11 {
  margin-top: 12px !important;
  margin-bottom: 11px !important;
}
.margin-v-12-10 {
  margin-top: 12px !important;
  margin-bottom: 10px !important;
}
.margin-v-12-9 {
  margin-top: 12px !important;
  margin-bottom: 9px !important;
}
.margin-v-12-8 {
  margin-top: 12px !important;
  margin-bottom: 8px !important;
}
.margin-v-12-7 {
  margin-top: 12px !important;
  margin-bottom: 7px !important;
}
.margin-v-12-6 {
  margin-top: 12px !important;
  margin-bottom: 6px !important;
}
.margin-v-12-5 {
  margin-top: 12px !important;
  margin-bottom: 5px !important;
}
.margin-v-12-4 {
  margin-top: 12px !important;
  margin-bottom: 4px !important;
}
.margin-v-12-3 {
  margin-top: 12px !important;
  margin-bottom: 3px !important;
}
.margin-v-12-2 {
  margin-top: 12px !important;
  margin-bottom: 2px !important;
}
.margin-v-12-1 {
  margin-top: 12px !important;
  margin-bottom: 1px !important;
}
.margin-v-12-0 {
  margin-top: 12px !important;
  margin-bottom: 0px !important;
}
.margin-v-13-15 {
  margin-top: 13px !important;
  margin-bottom: 15px !important;
}
.margin-v-13-14 {
  margin-top: 13px !important;
  margin-bottom: 14px !important;
}
.margin-v-13-12 {
  margin-top: 13px !important;
  margin-bottom: 12px !important;
}
.margin-v-13-11 {
  margin-top: 13px !important;
  margin-bottom: 11px !important;
}
.margin-v-13-10 {
  margin-top: 13px !important;
  margin-bottom: 10px !important;
}
.margin-v-13-9 {
  margin-top: 13px !important;
  margin-bottom: 9px !important;
}
.margin-v-13-8 {
  margin-top: 13px !important;
  margin-bottom: 8px !important;
}
.margin-v-13-7 {
  margin-top: 13px !important;
  margin-bottom: 7px !important;
}
.margin-v-13-6 {
  margin-top: 13px !important;
  margin-bottom: 6px !important;
}
.margin-v-13-5 {
  margin-top: 13px !important;
  margin-bottom: 5px !important;
}
.margin-v-13-4 {
  margin-top: 13px !important;
  margin-bottom: 4px !important;
}
.margin-v-13-3 {
  margin-top: 13px !important;
  margin-bottom: 3px !important;
}
.margin-v-13-2 {
  margin-top: 13px !important;
  margin-bottom: 2px !important;
}
.margin-v-13-1 {
  margin-top: 13px !important;
  margin-bottom: 1px !important;
}
.margin-v-13-0 {
  margin-top: 13px !important;
  margin-bottom: 0px !important;
}
.margin-v-14-15 {
  margin-top: 14px !important;
  margin-bottom: 15px !important;
}
.margin-v-14-13 {
  margin-top: 14px !important;
  margin-bottom: 13px !important;
}
.margin-v-14-12 {
  margin-top: 14px !important;
  margin-bottom: 12px !important;
}
.margin-v-14-11 {
  margin-top: 14px !important;
  margin-bottom: 11px !important;
}
.margin-v-14-10 {
  margin-top: 14px !important;
  margin-bottom: 10px !important;
}
.margin-v-14-9 {
  margin-top: 14px !important;
  margin-bottom: 9px !important;
}
.margin-v-14-8 {
  margin-top: 14px !important;
  margin-bottom: 8px !important;
}
.margin-v-14-7 {
  margin-top: 14px !important;
  margin-bottom: 7px !important;
}
.margin-v-14-6 {
  margin-top: 14px !important;
  margin-bottom: 6px !important;
}
.margin-v-14-5 {
  margin-top: 14px !important;
  margin-bottom: 5px !important;
}
.margin-v-14-4 {
  margin-top: 14px !important;
  margin-bottom: 4px !important;
}
.margin-v-14-3 {
  margin-top: 14px !important;
  margin-bottom: 3px !important;
}
.margin-v-14-2 {
  margin-top: 14px !important;
  margin-bottom: 2px !important;
}
.margin-v-14-1 {
  margin-top: 14px !important;
  margin-bottom: 1px !important;
}
.margin-v-14-0 {
  margin-top: 14px !important;
  margin-bottom: 0px !important;
}
.margin-v-15-14 {
  margin-top: 15px !important;
  margin-bottom: 14px !important;
}
.margin-v-15-13 {
  margin-top: 15px !important;
  margin-bottom: 13px !important;
}
.margin-v-15-12 {
  margin-top: 15px !important;
  margin-bottom: 12px !important;
}
.margin-v-15-11 {
  margin-top: 15px !important;
  margin-bottom: 11px !important;
}
.margin-v-15-10 {
  margin-top: 15px !important;
  margin-bottom: 10px !important;
}
.margin-v-15-9 {
  margin-top: 15px !important;
  margin-bottom: 9px !important;
}
.margin-v-15-8 {
  margin-top: 15px !important;
  margin-bottom: 8px !important;
}
.margin-v-15-7 {
  margin-top: 15px !important;
  margin-bottom: 7px !important;
}
.margin-v-15-6 {
  margin-top: 15px !important;
  margin-bottom: 6px !important;
}
.margin-v-15-5 {
  margin-top: 15px !important;
  margin-bottom: 5px !important;
}
.margin-v-15-4 {
  margin-top: 15px !important;
  margin-bottom: 4px !important;
}
.margin-v-15-3 {
  margin-top: 15px !important;
  margin-bottom: 3px !important;
}
.margin-v-15-2 {
  margin-top: 15px !important;
  margin-bottom: 2px !important;
}
.margin-v-15-1 {
  margin-top: 15px !important;
  margin-bottom: 1px !important;
}
.margin-v-15-0 {
  margin-top: 15px !important;
  margin-bottom: 0px !important;
}
.padding-v-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}
.padding-v-15-10 {
  padding-top: 15px !important;
  padding-bottom: 10px !important;
}
.padding-v-15-9 {
  padding-top: 15px !important;
  padding-bottom: 9px !important;
}
.padding-v-15-8 {
  padding-top: 15px !important;
  padding-bottom: 8px !important;
}
.padding-v-15-7 {
  padding-top: 15px !important;
  padding-bottom: 7px !important;
}
.padding-v-15-6 {
  padding-top: 15px !important;
  padding-bottom: 6px !important;
}
.padding-v-15-5 {
  padding-top: 15px !important;
  padding-bottom: 5px !important;
}
.padding-v-15-4 {
  padding-top: 15px !important;
  padding-bottom: 4px !important;
}
.padding-v-15-3 {
  padding-top: 15px !important;
  padding-bottom: 3px !important;
}
.padding-v-15-2 {
  padding-top: 15px !important;
  padding-bottom: 2px !important;
}
.padding-v-15-1 {
  padding-top: 15px !important;
  padding-bottom: 1px !important;
}
.padding-v-15-0 {
  padding-top: 15px !important;
  padding-bottom: 0px !important;
}
.padding-v-14 {
  padding-top: 14px !important;
  padding-bottom: 14px !important;
}
.padding-v-14-10 {
  padding-top: 14px !important;
  padding-bottom: 10px !important;
}
.padding-v-14-9 {
  padding-top: 14px !important;
  padding-bottom: 9px !important;
}
.padding-v-14-8 {
  padding-top: 14px !important;
  padding-bottom: 8px !important;
}
.padding-v-14-7 {
  padding-top: 14px !important;
  padding-bottom: 7px !important;
}
.padding-v-14-6 {
  padding-top: 14px !important;
  padding-bottom: 6px !important;
}
.padding-v-14-5 {
  padding-top: 14px !important;
  padding-bottom: 5px !important;
}
.padding-v-14-4 {
  padding-top: 14px !important;
  padding-bottom: 4px !important;
}
.padding-v-14-3 {
  padding-top: 14px !important;
  padding-bottom: 3px !important;
}
.padding-v-14-2 {
  padding-top: 14px !important;
  padding-bottom: 2px !important;
}
.padding-v-14-1 {
  padding-top: 14px !important;
  padding-bottom: 1px !important;
}
.padding-v-14-0 {
  padding-top: 14px !important;
  padding-bottom: 0px !important;
}
.padding-v-13 {
  padding-top: 13px !important;
  padding-bottom: 13px !important;
}
.padding-v-13-10 {
  padding-top: 13px !important;
  padding-bottom: 10px !important;
}
.padding-v-13-9 {
  padding-top: 13px !important;
  padding-bottom: 9px !important;
}
.padding-v-13-8 {
  padding-top: 13px !important;
  padding-bottom: 8px !important;
}
.padding-v-13-7 {
  padding-top: 13px !important;
  padding-bottom: 7px !important;
}
.padding-v-13-6 {
  padding-top: 13px !important;
  padding-bottom: 6px !important;
}
.padding-v-13-5 {
  padding-top: 13px !important;
  padding-bottom: 5px !important;
}
.padding-v-13-4 {
  padding-top: 13px !important;
  padding-bottom: 4px !important;
}
.padding-v-13-3 {
  padding-top: 13px !important;
  padding-bottom: 3px !important;
}
.padding-v-13-2 {
  padding-top: 13px !important;
  padding-bottom: 2px !important;
}
.padding-v-13-1 {
  padding-top: 13px !important;
  padding-bottom: 1px !important;
}
.padding-v-13-0 {
  padding-top: 13px !important;
  padding-bottom: 0px !important;
}
.padding-v-12 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}
.padding-v-12-10 {
  padding-top: 12px !important;
  padding-bottom: 10px !important;
}
.padding-v-12-9 {
  padding-top: 12px !important;
  padding-bottom: 9px !important;
}
.padding-v-12-8 {
  padding-top: 12px !important;
  padding-bottom: 8px !important;
}
.padding-v-12-7 {
  padding-top: 12px !important;
  padding-bottom: 7px !important;
}
.padding-v-12-6 {
  padding-top: 12px !important;
  padding-bottom: 6px !important;
}
.padding-v-12-5 {
  padding-top: 12px !important;
  padding-bottom: 5px !important;
}
.padding-v-12-4 {
  padding-top: 12px !important;
  padding-bottom: 4px !important;
}
.padding-v-12-3 {
  padding-top: 12px !important;
  padding-bottom: 3px !important;
}
.padding-v-12-2 {
  padding-top: 12px !important;
  padding-bottom: 2px !important;
}
.padding-v-12-1 {
  padding-top: 12px !important;
  padding-bottom: 1px !important;
}
.padding-v-12-0 {
  padding-top: 12px !important;
  padding-bottom: 0px !important;
}
.padding-v-11 {
  padding-top: 11px !important;
  padding-bottom: 11px !important;
}
.padding-v-11-10 {
  padding-top: 11px !important;
  padding-bottom: 10px !important;
}
.padding-v-11-9 {
  padding-top: 11px !important;
  padding-bottom: 9px !important;
}
.padding-v-11-8 {
  padding-top: 11px !important;
  padding-bottom: 8px !important;
}
.padding-v-11-7 {
  padding-top: 11px !important;
  padding-bottom: 7px !important;
}
.padding-v-11-6 {
  padding-top: 11px !important;
  padding-bottom: 6px !important;
}
.padding-v-11-5 {
  padding-top: 11px !important;
  padding-bottom: 5px !important;
}
.padding-v-11-4 {
  padding-top: 11px !important;
  padding-bottom: 4px !important;
}
.padding-v-11-3 {
  padding-top: 11px !important;
  padding-bottom: 3px !important;
}
.padding-v-11-2 {
  padding-top: 11px !important;
  padding-bottom: 2px !important;
}
.padding-v-11-1 {
  padding-top: 11px !important;
  padding-bottom: 1px !important;
}
.padding-v-11-0 {
  padding-top: 11px !important;
  padding-bottom: 0px !important;
}
.padding-v-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.padding-v-10-9 {
  padding-top: 10px !important;
  padding-bottom: 9px !important;
}
.padding-v-10-8 {
  padding-top: 10px !important;
  padding-bottom: 8px !important;
}
.padding-v-10-7 {
  padding-top: 10px !important;
  padding-bottom: 7px !important;
}
.padding-v-10-6 {
  padding-top: 10px !important;
  padding-bottom: 6px !important;
}
.padding-v-10-5 {
  padding-top: 10px !important;
  padding-bottom: 5px !important;
}
.padding-v-10-4 {
  padding-top: 10px !important;
  padding-bottom: 4px !important;
}
.padding-v-10-3 {
  padding-top: 10px !important;
  padding-bottom: 3px !important;
}
.padding-v-10-2 {
  padding-top: 10px !important;
  padding-bottom: 2px !important;
}
.padding-v-10-1 {
  padding-top: 10px !important;
  padding-bottom: 1px !important;
}
.padding-v-10-0 {
  padding-top: 10px !important;
  padding-bottom: 0px !important;
}
.padding-v-9 {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
}
.padding-v-9-10 {
  padding-top: 9px !important;
  padding-bottom: 10px !important;
}
.padding-v-9-8 {
  padding-top: 9px !important;
  padding-bottom: 8px !important;
}
.padding-v-9-7 {
  padding-top: 9px !important;
  padding-bottom: 7px !important;
}
.padding-v-9-6 {
  padding-top: 9px !important;
  padding-bottom: 6px !important;
}
.padding-v-9-5 {
  padding-top: 9px !important;
  padding-bottom: 5px !important;
}
.padding-v-9-4 {
  padding-top: 9px !important;
  padding-bottom: 4px !important;
}
.padding-v-9-3 {
  padding-top: 9px !important;
  padding-bottom: 3px !important;
}
.padding-v-9-2 {
  padding-top: 9px !important;
  padding-bottom: 2px !important;
}
.padding-v-9-1 {
  padding-top: 9px !important;
  padding-bottom: 1px !important;
}
.padding-v-9-0 {
  padding-top: 9px !important;
  padding-bottom: 0px !important;
}
.padding-v-8 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
.padding-v-8-10 {
  padding-top: 8px !important;
  padding-bottom: 10px !important;
}
.padding-v-8-9 {
  padding-top: 8px !important;
  padding-bottom: 9px !important;
}
.padding-v-8-7 {
  padding-top: 8px !important;
  padding-bottom: 7px !important;
}
.padding-v-8-6 {
  padding-top: 8px !important;
  padding-bottom: 6px !important;
}
.padding-v-8-5 {
  padding-top: 8px !important;
  padding-bottom: 5px !important;
}
.padding-v-8-4 {
  padding-top: 8px !important;
  padding-bottom: 4px !important;
}
.padding-v-8-3 {
  padding-top: 8px !important;
  padding-bottom: 3px !important;
}
.padding-v-8-2 {
  padding-top: 8px !important;
  padding-bottom: 2px !important;
}
.padding-v-8-1 {
  padding-top: 8px !important;
  padding-bottom: 1px !important;
}
.padding-v-8-0 {
  padding-top: 8px !important;
  padding-bottom: 0px !important;
}
.padding-v-7 {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}
.padding-v-7-10 {
  padding-top: 7px !important;
  padding-bottom: 10px !important;
}
.padding-v-7-9 {
  padding-top: 7px !important;
  padding-bottom: 9px !important;
}
.padding-v-7-8 {
  padding-top: 7px !important;
  padding-bottom: 8px !important;
}
.padding-v-7-6 {
  padding-top: 7px !important;
  padding-bottom: 6px !important;
}
.padding-v-7-5 {
  padding-top: 7px !important;
  padding-bottom: 5px !important;
}
.padding-v-7-4 {
  padding-top: 7px !important;
  padding-bottom: 4px !important;
}
.padding-v-7-3 {
  padding-top: 7px !important;
  padding-bottom: 3px !important;
}
.padding-v-7-2 {
  padding-top: 7px !important;
  padding-bottom: 2px !important;
}
.padding-v-7-1 {
  padding-top: 7px !important;
  padding-bottom: 1px !important;
}
.padding-v-7-0 {
  padding-top: 7px !important;
  padding-bottom: 0px !important;
}
.padding-v-6 {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}
.padding-v-6-10 {
  padding-top: 6px !important;
  padding-bottom: 10px !important;
}
.padding-v-6-9 {
  padding-top: 6px !important;
  padding-bottom: 9px !important;
}
.padding-v-6-8 {
  padding-top: 6px !important;
  padding-bottom: 8px !important;
}
.padding-v-6-7 {
  padding-top: 6px !important;
  padding-bottom: 7px !important;
}
.padding-v-6-5 {
  padding-top: 6px !important;
  padding-bottom: 5px !important;
}
.padding-v-6-4 {
  padding-top: 6px !important;
  padding-bottom: 4px !important;
}
.padding-v-6-3 {
  padding-top: 6px !important;
  padding-bottom: 3px !important;
}
.padding-v-6-2 {
  padding-top: 6px !important;
  padding-bottom: 2px !important;
}
.padding-v-6-1 {
  padding-top: 6px !important;
  padding-bottom: 1px !important;
}
.padding-v-6-0 {
  padding-top: 6px !important;
  padding-bottom: 0px !important;
}
.padding-v-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.padding-v-5-10 {
  padding-top: 5px !important;
  padding-bottom: 10px !important;
}
.padding-v-5-9 {
  padding-top: 5px !important;
  padding-bottom: 9px !important;
}
.padding-v-5-8 {
  padding-top: 5px !important;
  padding-bottom: 8px !important;
}
.padding-v-5-7 {
  padding-top: 5px !important;
  padding-bottom: 7px !important;
}
.padding-v-5-6 {
  padding-top: 5px !important;
  padding-bottom: 6px !important;
}
.padding-v-5-4 {
  padding-top: 5px !important;
  padding-bottom: 4px !important;
}
.padding-v-5-3 {
  padding-top: 5px !important;
  padding-bottom: 3px !important;
}
.padding-v-5-2 {
  padding-top: 5px !important;
  padding-bottom: 2px !important;
}
.padding-v-5-1 {
  padding-top: 5px !important;
  padding-bottom: 1px !important;
}
.padding-v-5-0 {
  padding-top: 5px !important;
  padding-bottom: 0px !important;
}
.padding-v-4 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}
.padding-v-4-10 {
  padding-top: 4px !important;
  padding-bottom: 10px !important;
}
.padding-v-4-9 {
  padding-top: 4px !important;
  padding-bottom: 9px !important;
}
.padding-v-4-8 {
  padding-top: 4px !important;
  padding-bottom: 8px !important;
}
.padding-v-4-7 {
  padding-top: 4px !important;
  padding-bottom: 7px !important;
}
.padding-v-4-6 {
  padding-top: 4px !important;
  padding-bottom: 6px !important;
}
.padding-v-4-5 {
  padding-top: 4px !important;
  padding-bottom: 5px !important;
}
.padding-v-4-3 {
  padding-top: 4px !important;
  padding-bottom: 3px !important;
}
.padding-v-4-2 {
  padding-top: 4px !important;
  padding-bottom: 2px !important;
}
.padding-v-4-1 {
  padding-top: 4px !important;
  padding-bottom: 1px !important;
}
.padding-v-4-0 {
  padding-top: 4px !important;
  padding-bottom: 0px !important;
}
.padding-v-3 {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}
.padding-v-3-10 {
  padding-top: 3px !important;
  padding-bottom: 10px !important;
}
.padding-v-3-9 {
  padding-top: 3px !important;
  padding-bottom: 9px !important;
}
.padding-v-3-8 {
  padding-top: 3px !important;
  padding-bottom: 8px !important;
}
.padding-v-3-7 {
  padding-top: 3px !important;
  padding-bottom: 7px !important;
}
.padding-v-3-6 {
  padding-top: 3px !important;
  padding-bottom: 6px !important;
}
.padding-v-3-5 {
  padding-top: 3px !important;
  padding-bottom: 5px !important;
}
.padding-v-3-4 {
  padding-top: 3px !important;
  padding-bottom: 4px !important;
}
.padding-v-3-2 {
  padding-top: 3px !important;
  padding-bottom: 2px !important;
}
.padding-v-3-1 {
  padding-top: 3px !important;
  padding-bottom: 1px !important;
}
.padding-v-3-0 {
  padding-top: 3px !important;
  padding-bottom: 0px !important;
}
.padding-v-2 {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}
.padding-v-2-10 {
  padding-top: 2px !important;
  padding-bottom: 10px !important;
}
.padding-v-2-9 {
  padding-top: 2px !important;
  padding-bottom: 9px !important;
}
.padding-v-2-8 {
  padding-top: 2px !important;
  padding-bottom: 8px !important;
}
.padding-v-2-7 {
  padding-top: 2px !important;
  padding-bottom: 7px !important;
}
.padding-v-2-6 {
  padding-top: 2px !important;
  padding-bottom: 6px !important;
}
.padding-v-2-5 {
  padding-top: 2px !important;
  padding-bottom: 5px !important;
}
.padding-v-2-4 {
  padding-top: 2px !important;
  padding-bottom: 4px !important;
}
.padding-v-2-3 {
  padding-top: 2px !important;
  padding-bottom: 3px !important;
}
.padding-v-2-1 {
  padding-top: 2px !important;
  padding-bottom: 1px !important;
}
.padding-v-2-0 {
  padding-top: 2px !important;
  padding-bottom: 0px !important;
}
.padding-v-1 {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}
.padding-v-1-10 {
  padding-top: 1px !important;
  padding-bottom: 10px !important;
}
.padding-v-1-9 {
  padding-top: 1px !important;
  padding-bottom: 9px !important;
}
.padding-v-1-8 {
  padding-top: 1px !important;
  padding-bottom: 8px !important;
}
.padding-v-1-7 {
  padding-top: 1px !important;
  padding-bottom: 7px !important;
}
.padding-v-1-6 {
  padding-top: 1px !important;
  padding-bottom: 6px !important;
}
.padding-v-1-5 {
  padding-top: 1px !important;
  padding-bottom: 5px !important;
}
.padding-v-1-4 {
  padding-top: 1px !important;
  padding-bottom: 4px !important;
}
.padding-v-1-3 {
  padding-top: 1px !important;
  padding-bottom: 3px !important;
}
.padding-v-1-2 {
  padding-top: 1px !important;
  padding-bottom: 2px !important;
}
.padding-v-1-0 {
  padding-top: 1px !important;
  padding-bottom: 0px !important;
}
.padding-v-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.padding-v-0-10 {
  padding-top: 0px !important;
  padding-bottom: 10px !important;
}
.padding-v-0-9 {
  padding-top: 0px !important;
  padding-bottom: 9px !important;
}
.padding-v-0-8 {
  padding-top: 0px !important;
  padding-bottom: 8px !important;
}
.padding-v-0-7 {
  padding-top: 0px !important;
  padding-bottom: 7px !important;
}
.padding-v-0-6 {
  padding-top: 0px !important;
  padding-bottom: 6px !important;
}
.padding-v-0-5 {
  padding-top: 0px !important;
  padding-bottom: 5px !important;
}
.padding-v-0-4 {
  padding-top: 0px !important;
  padding-bottom: 4px !important;
}
.padding-v-0-3 {
  padding-top: 0px !important;
  padding-bottom: 3px !important;
}
.padding-v-0-2 {
  padding-top: 0px !important;
  padding-bottom: 2px !important;
}
.padding-v-0-1 {
  padding-top: 0px !important;
  padding-bottom: 1px !important;
}
.padding-v-0-15 {
  padding-top: 0px !important;
  padding-bottom: 15px !important;
}
.padding-v-0-14 {
  padding-top: 0px !important;
  padding-bottom: 14px !important;
}
.padding-v-0-13 {
  padding-top: 0px !important;
  padding-bottom: 13px !important;
}
.padding-v-0-12 {
  padding-top: 0px !important;
  padding-bottom: 12px !important;
}
.padding-v-0-11 {
  padding-top: 0px !important;
  padding-bottom: 11px !important;
}
.padding-v-0-10 {
  padding-top: 0px !important;
  padding-bottom: 10px !important;
}
.padding-v-0-9 {
  padding-top: 0px !important;
  padding-bottom: 9px !important;
}
.padding-v-0-8 {
  padding-top: 0px !important;
  padding-bottom: 8px !important;
}
.padding-v-0-7 {
  padding-top: 0px !important;
  padding-bottom: 7px !important;
}
.padding-v-0-6 {
  padding-top: 0px !important;
  padding-bottom: 6px !important;
}
.padding-v-0-5 {
  padding-top: 0px !important;
  padding-bottom: 5px !important;
}
.padding-v-0-4 {
  padding-top: 0px !important;
  padding-bottom: 4px !important;
}
.padding-v-0-3 {
  padding-top: 0px !important;
  padding-bottom: 3px !important;
}
.padding-v-0-2 {
  padding-top: 0px !important;
  padding-bottom: 2px !important;
}
.padding-v-0-1 {
  padding-top: 0px !important;
  padding-bottom: 1px !important;
}
.padding-v-1-15 {
  padding-top: 1px !important;
  padding-bottom: 15px !important;
}
.padding-v-1-14 {
  padding-top: 1px !important;
  padding-bottom: 14px !important;
}
.padding-v-1-13 {
  padding-top: 1px !important;
  padding-bottom: 13px !important;
}
.padding-v-1-12 {
  padding-top: 1px !important;
  padding-bottom: 12px !important;
}
.padding-v-1-11 {
  padding-top: 1px !important;
  padding-bottom: 11px !important;
}
.padding-v-1-10 {
  padding-top: 1px !important;
  padding-bottom: 10px !important;
}
.padding-v-1-9 {
  padding-top: 1px !important;
  padding-bottom: 9px !important;
}
.padding-v-1-8 {
  padding-top: 1px !important;
  padding-bottom: 8px !important;
}
.padding-v-1-7 {
  padding-top: 1px !important;
  padding-bottom: 7px !important;
}
.padding-v-1-6 {
  padding-top: 1px !important;
  padding-bottom: 6px !important;
}
.padding-v-1-5 {
  padding-top: 1px !important;
  padding-bottom: 5px !important;
}
.padding-v-1-4 {
  padding-top: 1px !important;
  padding-bottom: 4px !important;
}
.padding-v-1-3 {
  padding-top: 1px !important;
  padding-bottom: 3px !important;
}
.padding-v-1-2 {
  padding-top: 1px !important;
  padding-bottom: 2px !important;
}
.padding-v-1-0 {
  padding-top: 1px !important;
  padding-bottom: 0px !important;
}
.padding-v-2-15 {
  padding-top: 2px !important;
  padding-bottom: 15px !important;
}
.padding-v-2-14 {
  padding-top: 2px !important;
  padding-bottom: 14px !important;
}
.padding-v-2-13 {
  padding-top: 2px !important;
  padding-bottom: 13px !important;
}
.padding-v-2-12 {
  padding-top: 2px !important;
  padding-bottom: 12px !important;
}
.padding-v-2-11 {
  padding-top: 2px !important;
  padding-bottom: 11px !important;
}
.padding-v-2-10 {
  padding-top: 2px !important;
  padding-bottom: 10px !important;
}
.padding-v-2-9 {
  padding-top: 2px !important;
  padding-bottom: 9px !important;
}
.padding-v-2-8 {
  padding-top: 2px !important;
  padding-bottom: 8px !important;
}
.padding-v-2-7 {
  padding-top: 2px !important;
  padding-bottom: 7px !important;
}
.padding-v-2-6 {
  padding-top: 2px !important;
  padding-bottom: 6px !important;
}
.padding-v-2-5 {
  padding-top: 2px !important;
  padding-bottom: 5px !important;
}
.padding-v-2-4 {
  padding-top: 2px !important;
  padding-bottom: 4px !important;
}
.padding-v-2-3 {
  padding-top: 2px !important;
  padding-bottom: 3px !important;
}
.padding-v-2-1 {
  padding-top: 2px !important;
  padding-bottom: 1px !important;
}
.padding-v-2-0 {
  padding-top: 2px !important;
  padding-bottom: 0px !important;
}
.padding-v-3-15 {
  padding-top: 3px !important;
  padding-bottom: 15px !important;
}
.padding-v-3-14 {
  padding-top: 3px !important;
  padding-bottom: 14px !important;
}
.padding-v-3-13 {
  padding-top: 3px !important;
  padding-bottom: 13px !important;
}
.padding-v-3-12 {
  padding-top: 3px !important;
  padding-bottom: 12px !important;
}
.padding-v-3-11 {
  padding-top: 3px !important;
  padding-bottom: 11px !important;
}
.padding-v-3-10 {
  padding-top: 3px !important;
  padding-bottom: 10px !important;
}
.padding-v-3-9 {
  padding-top: 3px !important;
  padding-bottom: 9px !important;
}
.padding-v-3-8 {
  padding-top: 3px !important;
  padding-bottom: 8px !important;
}
.padding-v-3-7 {
  padding-top: 3px !important;
  padding-bottom: 7px !important;
}
.padding-v-3-6 {
  padding-top: 3px !important;
  padding-bottom: 6px !important;
}
.padding-v-3-5 {
  padding-top: 3px !important;
  padding-bottom: 5px !important;
}
.padding-v-3-4 {
  padding-top: 3px !important;
  padding-bottom: 4px !important;
}
.padding-v-3-2 {
  padding-top: 3px !important;
  padding-bottom: 2px !important;
}
.padding-v-3-1 {
  padding-top: 3px !important;
  padding-bottom: 1px !important;
}
.padding-v-3-0 {
  padding-top: 3px !important;
  padding-bottom: 0px !important;
}
.padding-v-4-15 {
  padding-top: 4px !important;
  padding-bottom: 15px !important;
}
.padding-v-4-14 {
  padding-top: 4px !important;
  padding-bottom: 14px !important;
}
.padding-v-4-13 {
  padding-top: 4px !important;
  padding-bottom: 13px !important;
}
.padding-v-4-12 {
  padding-top: 4px !important;
  padding-bottom: 12px !important;
}
.padding-v-4-11 {
  padding-top: 4px !important;
  padding-bottom: 11px !important;
}
.padding-v-4-10 {
  padding-top: 4px !important;
  padding-bottom: 10px !important;
}
.padding-v-4-9 {
  padding-top: 4px !important;
  padding-bottom: 9px !important;
}
.padding-v-4-8 {
  padding-top: 4px !important;
  padding-bottom: 8px !important;
}
.padding-v-4-7 {
  padding-top: 4px !important;
  padding-bottom: 7px !important;
}
.padding-v-4-6 {
  padding-top: 4px !important;
  padding-bottom: 6px !important;
}
.padding-v-4-5 {
  padding-top: 4px !important;
  padding-bottom: 5px !important;
}
.padding-v-4-3 {
  padding-top: 4px !important;
  padding-bottom: 3px !important;
}
.padding-v-4-2 {
  padding-top: 4px !important;
  padding-bottom: 2px !important;
}
.padding-v-4-1 {
  padding-top: 4px !important;
  padding-bottom: 1px !important;
}
.padding-v-4-0 {
  padding-top: 4px !important;
  padding-bottom: 0px !important;
}
.padding-v-5-15 {
  padding-top: 5px !important;
  padding-bottom: 15px !important;
}
.padding-v-5-14 {
  padding-top: 5px !important;
  padding-bottom: 14px !important;
}
.padding-v-5-13 {
  padding-top: 5px !important;
  padding-bottom: 13px !important;
}
.padding-v-5-12 {
  padding-top: 5px !important;
  padding-bottom: 12px !important;
}
.padding-v-5-11 {
  padding-top: 5px !important;
  padding-bottom: 11px !important;
}
.padding-v-5-10 {
  padding-top: 5px !important;
  padding-bottom: 10px !important;
}
.padding-v-5-9 {
  padding-top: 5px !important;
  padding-bottom: 9px !important;
}
.padding-v-5-8 {
  padding-top: 5px !important;
  padding-bottom: 8px !important;
}
.padding-v-5-7 {
  padding-top: 5px !important;
  padding-bottom: 7px !important;
}
.padding-v-5-6 {
  padding-top: 5px !important;
  padding-bottom: 6px !important;
}
.padding-v-5-4 {
  padding-top: 5px !important;
  padding-bottom: 4px !important;
}
.padding-v-5-3 {
  padding-top: 5px !important;
  padding-bottom: 3px !important;
}
.padding-v-5-2 {
  padding-top: 5px !important;
  padding-bottom: 2px !important;
}
.padding-v-5-1 {
  padding-top: 5px !important;
  padding-bottom: 1px !important;
}
.padding-v-5-0 {
  padding-top: 5px !important;
  padding-bottom: 0px !important;
}
.padding-v-6-15 {
  padding-top: 6px !important;
  padding-bottom: 15px !important;
}
.padding-v-6-14 {
  padding-top: 6px !important;
  padding-bottom: 14px !important;
}
.padding-v-6-13 {
  padding-top: 6px !important;
  padding-bottom: 13px !important;
}
.padding-v-6-12 {
  padding-top: 6px !important;
  padding-bottom: 12px !important;
}
.padding-v-6-11 {
  padding-top: 6px !important;
  padding-bottom: 11px !important;
}
.padding-v-6-10 {
  padding-top: 6px !important;
  padding-bottom: 10px !important;
}
.padding-v-6-9 {
  padding-top: 6px !important;
  padding-bottom: 9px !important;
}
.padding-v-6-8 {
  padding-top: 6px !important;
  padding-bottom: 8px !important;
}
.padding-v-6-7 {
  padding-top: 6px !important;
  padding-bottom: 7px !important;
}
.padding-v-6-5 {
  padding-top: 6px !important;
  padding-bottom: 5px !important;
}
.padding-v-6-4 {
  padding-top: 6px !important;
  padding-bottom: 4px !important;
}
.padding-v-6-3 {
  padding-top: 6px !important;
  padding-bottom: 3px !important;
}
.padding-v-6-2 {
  padding-top: 6px !important;
  padding-bottom: 2px !important;
}
.padding-v-6-1 {
  padding-top: 6px !important;
  padding-bottom: 1px !important;
}
.padding-v-6-0 {
  padding-top: 6px !important;
  padding-bottom: 0px !important;
}
.padding-v-7-15 {
  padding-top: 7px !important;
  padding-bottom: 15px !important;
}
.padding-v-7-14 {
  padding-top: 7px !important;
  padding-bottom: 14px !important;
}
.padding-v-7-13 {
  padding-top: 7px !important;
  padding-bottom: 13px !important;
}
.padding-v-7-12 {
  padding-top: 7px !important;
  padding-bottom: 12px !important;
}
.padding-v-7-11 {
  padding-top: 7px !important;
  padding-bottom: 11px !important;
}
.padding-v-7-10 {
  padding-top: 7px !important;
  padding-bottom: 10px !important;
}
.padding-v-7-9 {
  padding-top: 7px !important;
  padding-bottom: 9px !important;
}
.padding-v-7-8 {
  padding-top: 7px !important;
  padding-bottom: 8px !important;
}
.padding-v-7-6 {
  padding-top: 7px !important;
  padding-bottom: 6px !important;
}
.padding-v-7-5 {
  padding-top: 7px !important;
  padding-bottom: 5px !important;
}
.padding-v-7-4 {
  padding-top: 7px !important;
  padding-bottom: 4px !important;
}
.padding-v-7-3 {
  padding-top: 7px !important;
  padding-bottom: 3px !important;
}
.padding-v-7-2 {
  padding-top: 7px !important;
  padding-bottom: 2px !important;
}
.padding-v-7-1 {
  padding-top: 7px !important;
  padding-bottom: 1px !important;
}
.padding-v-7-0 {
  padding-top: 7px !important;
  padding-bottom: 0px !important;
}
.padding-v-8-15 {
  padding-top: 8px !important;
  padding-bottom: 15px !important;
}
.padding-v-8-14 {
  padding-top: 8px !important;
  padding-bottom: 14px !important;
}
.padding-v-8-13 {
  padding-top: 8px !important;
  padding-bottom: 13px !important;
}
.padding-v-8-12 {
  padding-top: 8px !important;
  padding-bottom: 12px !important;
}
.padding-v-8-11 {
  padding-top: 8px !important;
  padding-bottom: 11px !important;
}
.padding-v-8-10 {
  padding-top: 8px !important;
  padding-bottom: 10px !important;
}
.padding-v-8-9 {
  padding-top: 8px !important;
  padding-bottom: 9px !important;
}
.padding-v-8-7 {
  padding-top: 8px !important;
  padding-bottom: 7px !important;
}
.padding-v-8-6 {
  padding-top: 8px !important;
  padding-bottom: 6px !important;
}
.padding-v-8-5 {
  padding-top: 8px !important;
  padding-bottom: 5px !important;
}
.padding-v-8-4 {
  padding-top: 8px !important;
  padding-bottom: 4px !important;
}
.padding-v-8-3 {
  padding-top: 8px !important;
  padding-bottom: 3px !important;
}
.padding-v-8-2 {
  padding-top: 8px !important;
  padding-bottom: 2px !important;
}
.padding-v-8-1 {
  padding-top: 8px !important;
  padding-bottom: 1px !important;
}
.padding-v-8-0 {
  padding-top: 8px !important;
  padding-bottom: 0px !important;
}
.padding-v-9-15 {
  padding-top: 9px !important;
  padding-bottom: 15px !important;
}
.padding-v-9-14 {
  padding-top: 9px !important;
  padding-bottom: 14px !important;
}
.padding-v-9-13 {
  padding-top: 9px !important;
  padding-bottom: 13px !important;
}
.padding-v-9-12 {
  padding-top: 9px !important;
  padding-bottom: 12px !important;
}
.padding-v-9-11 {
  padding-top: 9px !important;
  padding-bottom: 11px !important;
}
.padding-v-9-10 {
  padding-top: 9px !important;
  padding-bottom: 10px !important;
}
.padding-v-9-8 {
  padding-top: 9px !important;
  padding-bottom: 8px !important;
}
.padding-v-9-7 {
  padding-top: 9px !important;
  padding-bottom: 7px !important;
}
.padding-v-9-6 {
  padding-top: 9px !important;
  padding-bottom: 6px !important;
}
.padding-v-9-5 {
  padding-top: 9px !important;
  padding-bottom: 5px !important;
}
.padding-v-9-4 {
  padding-top: 9px !important;
  padding-bottom: 4px !important;
}
.padding-v-9-3 {
  padding-top: 9px !important;
  padding-bottom: 3px !important;
}
.padding-v-9-2 {
  padding-top: 9px !important;
  padding-bottom: 2px !important;
}
.padding-v-9-1 {
  padding-top: 9px !important;
  padding-bottom: 1px !important;
}
.padding-v-9-0 {
  padding-top: 9px !important;
  padding-bottom: 0px !important;
}
.padding-v-10-15 {
  padding-top: 10px !important;
  padding-bottom: 15px !important;
}
.padding-v-10-14 {
  padding-top: 10px !important;
  padding-bottom: 14px !important;
}
.padding-v-10-13 {
  padding-top: 10px !important;
  padding-bottom: 13px !important;
}
.padding-v-10-12 {
  padding-top: 10px !important;
  padding-bottom: 12px !important;
}
.padding-v-10-11 {
  padding-top: 10px !important;
  padding-bottom: 11px !important;
}
.padding-v-10-9 {
  padding-top: 10px !important;
  padding-bottom: 9px !important;
}
.padding-v-10-8 {
  padding-top: 10px !important;
  padding-bottom: 8px !important;
}
.padding-v-10-7 {
  padding-top: 10px !important;
  padding-bottom: 7px !important;
}
.padding-v-10-6 {
  padding-top: 10px !important;
  padding-bottom: 6px !important;
}
.padding-v-10-5 {
  padding-top: 10px !important;
  padding-bottom: 5px !important;
}
.padding-v-10-4 {
  padding-top: 10px !important;
  padding-bottom: 4px !important;
}
.padding-v-10-3 {
  padding-top: 10px !important;
  padding-bottom: 3px !important;
}
.padding-v-10-2 {
  padding-top: 10px !important;
  padding-bottom: 2px !important;
}
.padding-v-10-1 {
  padding-top: 10px !important;
  padding-bottom: 1px !important;
}
.padding-v-10-0 {
  padding-top: 10px !important;
  padding-bottom: 0px !important;
}
.padding-v-11-15 {
  padding-top: 11px !important;
  padding-bottom: 15px !important;
}
.padding-v-11-14 {
  padding-top: 11px !important;
  padding-bottom: 14px !important;
}
.padding-v-11-13 {
  padding-top: 11px !important;
  padding-bottom: 13px !important;
}
.padding-v-11-12 {
  padding-top: 11px !important;
  padding-bottom: 12px !important;
}
.padding-v-11-10 {
  padding-top: 11px !important;
  padding-bottom: 10px !important;
}
.padding-v-11-9 {
  padding-top: 11px !important;
  padding-bottom: 9px !important;
}
.padding-v-11-8 {
  padding-top: 11px !important;
  padding-bottom: 8px !important;
}
.padding-v-11-7 {
  padding-top: 11px !important;
  padding-bottom: 7px !important;
}
.padding-v-11-6 {
  padding-top: 11px !important;
  padding-bottom: 6px !important;
}
.padding-v-11-5 {
  padding-top: 11px !important;
  padding-bottom: 5px !important;
}
.padding-v-11-4 {
  padding-top: 11px !important;
  padding-bottom: 4px !important;
}
.padding-v-11-3 {
  padding-top: 11px !important;
  padding-bottom: 3px !important;
}
.padding-v-11-2 {
  padding-top: 11px !important;
  padding-bottom: 2px !important;
}
.padding-v-11-1 {
  padding-top: 11px !important;
  padding-bottom: 1px !important;
}
.padding-v-11-0 {
  padding-top: 11px !important;
  padding-bottom: 0px !important;
}
.padding-v-12-15 {
  padding-top: 12px !important;
  padding-bottom: 15px !important;
}
.padding-v-12-14 {
  padding-top: 12px !important;
  padding-bottom: 14px !important;
}
.padding-v-12-13 {
  padding-top: 12px !important;
  padding-bottom: 13px !important;
}
.padding-v-12-11 {
  padding-top: 12px !important;
  padding-bottom: 11px !important;
}
.padding-v-12-10 {
  padding-top: 12px !important;
  padding-bottom: 10px !important;
}
.padding-v-12-9 {
  padding-top: 12px !important;
  padding-bottom: 9px !important;
}
.padding-v-12-8 {
  padding-top: 12px !important;
  padding-bottom: 8px !important;
}
.padding-v-12-7 {
  padding-top: 12px !important;
  padding-bottom: 7px !important;
}
.padding-v-12-6 {
  padding-top: 12px !important;
  padding-bottom: 6px !important;
}
.padding-v-12-5 {
  padding-top: 12px !important;
  padding-bottom: 5px !important;
}
.padding-v-12-4 {
  padding-top: 12px !important;
  padding-bottom: 4px !important;
}
.padding-v-12-3 {
  padding-top: 12px !important;
  padding-bottom: 3px !important;
}
.padding-v-12-2 {
  padding-top: 12px !important;
  padding-bottom: 2px !important;
}
.padding-v-12-1 {
  padding-top: 12px !important;
  padding-bottom: 1px !important;
}
.padding-v-12-0 {
  padding-top: 12px !important;
  padding-bottom: 0px !important;
}
.padding-v-13-15 {
  padding-top: 13px !important;
  padding-bottom: 15px !important;
}
.padding-v-13-14 {
  padding-top: 13px !important;
  padding-bottom: 14px !important;
}
.padding-v-13-12 {
  padding-top: 13px !important;
  padding-bottom: 12px !important;
}
.padding-v-13-11 {
  padding-top: 13px !important;
  padding-bottom: 11px !important;
}
.padding-v-13-10 {
  padding-top: 13px !important;
  padding-bottom: 10px !important;
}
.padding-v-13-9 {
  padding-top: 13px !important;
  padding-bottom: 9px !important;
}
.padding-v-13-8 {
  padding-top: 13px !important;
  padding-bottom: 8px !important;
}
.padding-v-13-7 {
  padding-top: 13px !important;
  padding-bottom: 7px !important;
}
.padding-v-13-6 {
  padding-top: 13px !important;
  padding-bottom: 6px !important;
}
.padding-v-13-5 {
  padding-top: 13px !important;
  padding-bottom: 5px !important;
}
.padding-v-13-4 {
  padding-top: 13px !important;
  padding-bottom: 4px !important;
}
.padding-v-13-3 {
  padding-top: 13px !important;
  padding-bottom: 3px !important;
}
.padding-v-13-2 {
  padding-top: 13px !important;
  padding-bottom: 2px !important;
}
.padding-v-13-1 {
  padding-top: 13px !important;
  padding-bottom: 1px !important;
}
.padding-v-13-0 {
  padding-top: 13px !important;
  padding-bottom: 0px !important;
}
.padding-v-14-15 {
  padding-top: 14px !important;
  padding-bottom: 15px !important;
}
.padding-v-14-13 {
  padding-top: 14px !important;
  padding-bottom: 13px !important;
}
.padding-v-14-12 {
  padding-top: 14px !important;
  padding-bottom: 12px !important;
}
.padding-v-14-11 {
  padding-top: 14px !important;
  padding-bottom: 11px !important;
}
.padding-v-14-10 {
  padding-top: 14px !important;
  padding-bottom: 10px !important;
}
.padding-v-14-9 {
  padding-top: 14px !important;
  padding-bottom: 9px !important;
}
.padding-v-14-8 {
  padding-top: 14px !important;
  padding-bottom: 8px !important;
}
.padding-v-14-7 {
  padding-top: 14px !important;
  padding-bottom: 7px !important;
}
.padding-v-14-6 {
  padding-top: 14px !important;
  padding-bottom: 6px !important;
}
.padding-v-14-5 {
  padding-top: 14px !important;
  padding-bottom: 5px !important;
}
.padding-v-14-4 {
  padding-top: 14px !important;
  padding-bottom: 4px !important;
}
.padding-v-14-3 {
  padding-top: 14px !important;
  padding-bottom: 3px !important;
}
.padding-v-14-2 {
  padding-top: 14px !important;
  padding-bottom: 2px !important;
}
.padding-v-14-1 {
  padding-top: 14px !important;
  padding-bottom: 1px !important;
}
.padding-v-14-0 {
  padding-top: 14px !important;
  padding-bottom: 0px !important;
}
.padding-v-15-14 {
  padding-top: 15px !important;
  padding-bottom: 14px !important;
}
.padding-v-15-13 {
  padding-top: 15px !important;
  padding-bottom: 13px !important;
}
.padding-v-15-12 {
  padding-top: 15px !important;
  padding-bottom: 12px !important;
}
.padding-v-15-11 {
  padding-top: 15px !important;
  padding-bottom: 11px !important;
}
.padding-v-15-10 {
  padding-top: 15px !important;
  padding-bottom: 10px !important;
}
.padding-v-15-9 {
  padding-top: 15px !important;
  padding-bottom: 9px !important;
}
.padding-v-15-8 {
  padding-top: 15px !important;
  padding-bottom: 8px !important;
}
.padding-v-15-7 {
  padding-top: 15px !important;
  padding-bottom: 7px !important;
}
.padding-v-15-6 {
  padding-top: 15px !important;
  padding-bottom: 6px !important;
}
.padding-v-15-5 {
  padding-top: 15px !important;
  padding-bottom: 5px !important;
}
.padding-v-15-4 {
  padding-top: 15px !important;
  padding-bottom: 4px !important;
}
.padding-v-15-3 {
  padding-top: 15px !important;
  padding-bottom: 3px !important;
}
.padding-v-15-2 {
  padding-top: 15px !important;
  padding-bottom: 2px !important;
}
.padding-v-15-1 {
  padding-top: 15px !important;
  padding-bottom: 1px !important;
}
.padding-v-15-0 {
  padding-top: 15px !important;
  padding-bottom: 0px !important;
}