/*------------------------------------------------------------------------------

INDEX:

1. CORE VARIABLES AND MIXINS

2. SETUP
- 2.1 Defaults
- 2.2 Hyperlinks
- 2.3 Skins

3. SITE STRUCTURE & APPEARANCE
- 3.1 Columns & Containeres
- 3.2 Navigation
- 3.3 Header
- 3.4 Content
- 3.5 Sidebar
- 3.6 Footer
- 3.7 Homepage specific
- 3.8 Enrollment

4. COMPONENTS
- 4.1 Media

------------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------*/
/* 1. CORE VARIABLES AND MIXINS */
/*----------------------------------------------------------------------------*/
@import "bootstrap";
@import "globetrotting/essentials";

/*----------------------------------------------------------------------------*/
/* 2. SETUP */
/*----------------------------------------------------------------------------*/

html {
	line-height: 13px;
	font-size: 13px;
	-webkit-font-smoothing: antialiased;
}

body {
	line-height: 1.53rem;
	font-size: 1rem;
	padding-top: 52px;
}

// Icon blocks.
.icon-block {
	width: 100px;
	height: 100px;
	line-height: 100px;
	text-align: center;
	display: inline-block;
	font-size: 35px;
}

.icon-block.p25 {
	width: 25px;
	height: 25px;
	line-height: 25px;
	font-size: 14px;
}

.icon-block.p30 {
	width: 30px;
	height: 30px;
	line-height: 30px;
	font-size: 15px;
}
.icon-block.p60 {
	width: 60px;
	height: 60px;
	line-height: 60px;
	font-size: 25px;
}

.icon-block.p90 {
	width: 90px;
	height: 90px;
	line-height: 90px;
}
.icon-block.half {
	width: 50px;
	height: 50px;
	line-height: 50px;
	font-size: 25px;
}

.icon-block.double {
	width: 150px;
	height: 150px;
	line-height: 150px;
	font-size: 45px;
}

// Text displays.
.text-display-4 {
	font-size: 8.6rem;
	font-weight: 300;
	line-height: 8.6rem;
}

.text-display-3 {
	font-size: 4.3rem;
	font-weight: 400;
	line-height: 8.6rem;
}

.text-display-2 {
	font-size: 3.46rem;
	font-weight: 400;
	line-height: 3.69rem;
}
.text-display-1 {
	font-size: 2.61rem;
	font-weight: 400;
	line-height: 3.07rem;
}

.text-headline {
	font-size: 1.84rem;
	font-weight: 400;
	line-height: 2.46rem;
}

.text-title {
	font-size: 1.53rem;
	font-weight: 500;
}

.text-subhead {
	font-size: 1.15rem;
	font-weight: 400;
	line-height: 1.84rem;
}

.text-subhead-2 {
	font-weight: 500;
	line-height: 2.15rem;
}

.text-caption {
	font-size: 0.92rem;
	font-weight: 400;
}

.text-body-2 {
	font-weight: 500;
	line-height: 1.84rem;
}

.text-light {
	color: #757575;
}

.text-headline > a {
	color: #212121;
}

h1, h2, h3, h4, h5, h6 {
	font-weight: 500;
}

/* 2.2 Hyperlinks */
a {
	background-color: transparent;
	color: #11499f;
}

/* 2.3 Skins */
.bg-primary {
	a { color: #FFF; }
	.label { background-color: darken($brand-primary, 10%); }
}
.bg-internshiptool {
	a { color: #FFF; }
	.label { background-color: darken(#27459c, 10%); }
}
.bg-internshiptool {
	color: white;
	background-color: #27459c;
}

/*----------------------------------------------------------------------------*/
/* 3. SITE STRUCTURE & APPEARANCE */
/*----------------------------------------------------------------------------*/

@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
	.pull-md-left {
		float: left;
	}
	.pull-md-right {
		float: right;
	}
}

@media (min-width: $screen-lg-min) {
	.pull-lg-left {
		float: left;
	}
	.pull-lg-right {
		float: right;
	}
}

// Backgrounds
.bg-white {
	background-color: #FFFFFF;
}

// Buttons
.btn-white {
	color: #444444;
	background-color: #FFFFFF;
	border-color: #EFEFEF;
	
	&:hover, &:focus { background-color: #EFEFEF; }
}

// Sections.
.page-section {
	padding: 40px 0;

	&.small-padding { padding: 24px 0; }
}

.lock-container {
	position: relative;
	margin: 0 auto;
	max-width: 600px!important;
	text-align: center;

	.login, .signup { display: block; margin: 10px auto 0 auto; }
  	.login { width: 100% }
}

/* 3.2 Navigation - Dropdowns */
.dropdown-menu {
	border: 0;
	border-radius: 0;
	padding: 0;
	font-size: 1rem;
	box-shadow: 0 2px 3px #E0E0E0;
}

.dropdown-menu > li >a, 
.dropdown-menu > li >a:focus {
	padding: 8px 14px;
	border-top: 1px solid #EFEFEF;
}

.dropdown-menu>li.active>a {
	background: #F5F7F7;
	color: $brand-primary;
}

/* 3.4 Header */
.navbar.navbar-default {
	min-height: 52px;
	background: #FFFFFF;
	border-color: #EEEEEE;
	border-bottom: 1px solid #E2E9E6;

	.navbar-brand {
		min-height: 51px;
		border-right: 1px solid transparent;
		border-right-color: #EEEEEE;
	}

	.navbar-brand-logo {
		img {
			width: 200px;
		}
		padding-top: 12px;
	}

	.navbar-nav > li > a {
		padding-top: 18px;
		padding-bottom: 12px;
		img {
			width: 20px;
		}
	}

	&:after {
		box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.37);
	}
}

/* 3.6 Footer */
.footer {
	display: block;
	text-align: center;
	color: #888;
	font-weight: 500;
	background: #FFFFFF;
	height: 40px;
	line-height: 40px;
	position: fixed;
	border-top: 1px solid #EEEEEE;
	bottom: 0;
	left: 0;
	right: 0;
	margin: 0;
	z-index: 101;
}

/* 3.7 Homepage specific */

/* 3.8 Enrollment */
button.enrollment-status-pending {
	background-color: #42A5F5;
}

button.enrollment-status-denied {
	background-color: #BD362F;
}

button.enrollment-status-enrolled {
	background-color: #4CAF50;
}

/*----------------------------------------------------------------------------*/
/* 4. COMPONENTS */
/*----------------------------------------------------------------------------*/

.media {
	margin-top: 15px;

	&:first-child {
		margin-top: 0;
	}
}

.media, .media-body {
	zoom: 1;
	overflow: inherit;
}

.media-left, .media-body, .media-right {
	display: table-cell;
	vertical-align: top;
}

.media-left { padding-right: 10px; }
.media-body { 
	width: 10000px;

	> :first-child { margin-top: 0; }
	> :last-child { margin-bottom: 0; }
}
.media-right { padding-left: 10px; }

.media.v-middle {
	> .media-left, > .media-body, > .media-right {
		vertical-align: middle;
	}
}

.label {
	display: inline-block;
	padding: 5px 10px;
	font-size: 12px;
	line-height: 1.5;
	border-radius: 2px;
	transition: box-shadow 0.3s ease;
}

.label-small {
	padding: .2em .6em .3em;
}

.label-secondary {
	background-color: white;
	border: 1px solid #ccc;
	color: #444;
}

a:hover .label {
	box-shadow: 0px 0px 8px #4bd1e8;
}

label.control-label {
	font-weight: normal;
}

.form-group.required .control-label:after {
    content: "*";
    position: absolute;
    margin-left: 8px;

	font-weight: bold;
	font-size: 12px;
}

@media (min-width: $screen-sm-min) {
	.form-horizontal label.control-label {
		text-align: left;
	}
}

h3.panel-title {
	font-weight: bold;
}

label.checkbox-inline, label.radio-inline {
	font-size: 12px;
}

.form-group {
	margin-bottom: 8px;
}

.form-horizontal .radio, .form-horizontal .checkbox, .form-horizontal .radio-inline, .form-horizontal .checkbox-inline {
	margin-bottom: 4px;
}

.form-group-sm .form-control-static {
	min-height: 30px;
}

.panel-body>:last-child, .panel-heading>:last-child {
	margin-bottom: 0;
}

.multi-line {
	white-space: pre-wrap;
}

.align-both {
	vertical-align: middle;
	text-align: center;
}

sup.remark {
	font-style: italic;
	font-weight: bold;
}

/**
 * Based on rigorous testing, we found that a width of 70 pixels
 * is just enough to hold the value "Yes".
 * @author Marcin Koziuk <marcin@irp.nl>
 * @author Ruben Dijkstra <ruben@irp.nl>
 * @see http://en.wikipedia.org/wiki/yes
 */
.yes-no-select {
	width: 70px;
}

.yes-no-compact-select {
	width: 30px;
}

.th-small {
	font-size: 8pt;
}

table.file-table {
	tbody tr {
		td, th {
			padding: 4px 4px;
		}
	}

	ul {
		margin-bottom: 0;
		padding-left: 1.5em;
	}
}

@media (min-width: $screen-sm-min) {
	.form-inline {
		.form-control.input-address {
			width: 349px;
		}
		.form-control.input-house-number {
			width: 80px;
		}
	}
}
.inline-label {
	padding: 0 5px;
}
.has-error {
	color: #df403c;
}

.panel {
	border-color: #d8d8d8;
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

@media (min-width: $screen-sm-min) {
	.col-no-pad-left {
		padding-right: 0;
	}
	.col-no-pad-middle {
		padding-left: 0;
		padding-right: 0;
	}
	.col-no-pad-right {
		padding-left: 0;
	}
}
.instance-link {
	.panel-body {
		padding: 12px;
		text-align: center;
	}
}
.instance-name {
	font-size: 11pt;
	font-weight: bold;
}
.instance-link-sm {
	.panel-body {
		padding: 8px;
		text-align: center;
	}
	.instance-name {
		font-size: 9pt;
	}
}
.instance-active {
	.instance-name {
		color: white;
	}
	.panel {
		border-color: #1d1448;
	}
	.panel-body {
		background: #25167A;
	}
}

#internshiptool-jumbotron {
	padding: 0;
	background-image: url("../images/hbo-ict-background.png");
	background-size: 70%;
	background-repeat: no-repeat;
	background-position: 50% -340px;
	background-attachment: initial;
	background-color: #11499f;
	color: white;
	position: relative;
	font-family: Montserrat, Roboto, sans-serif;
	z-index: 0;
}
#internshiptool-jumbotron .panel-body {
	color: #000;
}

#internshiptool-jumbotron .page-header {
	border-bottom: none;
}

#internshiptool-jumbotron .container {
	position: relative;
	z-index: 2;
}
#internshiptool-title {
	font-weight: 400;
	text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
}
#internshiptool-locaties {
  font-family: Montserrat, Roboto, sans-serif;
  font-weight: 500;
}

#internshiptool-pagination {
  font-family: Montserrat, Roboto, sans-serif;
}

.internships-none {
	padding: 15px 20px;
}

.internship-row {
  height: 100%;

  .internship-description {
    position: relative;
    max-height: 7.65rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .internship-description:after {
    content: "";
    position: absolute;
    text-align: left;
    top: 5.6rem;
    left: 0;
    height: 2.5em;
    width: 100%;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 80%, rgba(255, 255, 255, 1) 100%);
  }

	.internship-title {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

  .internship-thumbnail {
    margin-top: 15px;
    background-color: white;
    border: none;
  }

  .company-name {
    font-size: 11px;
	  overflow: hidden;
	  white-space: nowrap;
	  text-overflow: ellipsis;
  }
}

.internship-row:hover {
	.company-name {
		white-space: normal;
	}
	.internship-title {
		white-space: normal;
	}
}

.internship-description {
	h1, h2, h3, h4, h5, h6 {
		margin-top: 10px;
		margin-bottom: 5px;
	}
	h1, h2, h3, h4, h5, h6 {
		color: #000;
		font-size: 13px;
	}
}

.internship-row {
	position: relative;
	padding-top: 10px;
	padding-bottom: 10px;
	transition: box-shadow 0.5s;
	transition: opacity 0.3s;
	box-shadow: none;
	cursor: pointer;
	z-index: 1;
}
.internship-row.not-hover {
	//background: #f5f5f5;
}
.internship-row:hover {
	z-index: 5;
	box-shadow: 1px 1px 18px rgba(0, 0, 0, 0.24);
}
.internship-row:not(:last-child) {
	border-bottom: 1px solid #dfdfdf;
}
.internship-row .metadata {
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.internshiptype-label.control-label {
	padding-right: 15px;
}

.internships-body {
	padding-top: 0;
	padding-bottom: 0;
	font-family: 'Montserrat', Raleway, Roboto, 'sans-serif';
	font-size: 13px;
	font-weight: 400;
	color: #363636;
}
.internship-row h4 {
	font-family: 'Montserrat';
	font-weight: 400;
}
.internship-content {
	font-family: 'Montserrat';
}
.metadata {
	font-family: 'Montserrat';
	color: #000;
	font-size: 1rem;
	line-height: 17px;
}
.metadata-info {
	color: #292929;
	font-size: 14px;
	font-weight: 500;
}
.metadata-tags,
.metadata-published {
	color: #616161;
	font-weight: 400;
}
.metadata-tags {
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
}
.btn {
	font-family: 'Montserrat';
}
